import { writable } from 'svelte/store';
import { locale } from 'svelte-i18n';
import { browser } from '$app/environment';

function createLocaleStore() {
	const { subscribe, set } = writable(getInitialLocale());

	return {
		subscribe,
		set: (newLocale: string) => {
			if (browser) {
				localStorage.setItem('userLocale', newLocale);
			}
			locale.set(newLocale);
			set(newLocale);
		},
		get: () => {
			return getInitialLocale();
		}
	};
}

function getInitialLocale() {
	if (browser) {
		return localStorage.getItem('userLocale') || 'en';
	}
	return 'en';
}

export const userLocale = createLocaleStore();